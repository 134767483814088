import React from "react"
import { PublicLayout } from '../components/layout'
import Spinner from '../components/Spinner'

const IndexPage = () => {
  return <PublicLayout>
    <h4>Spinner</h4>
    <Spinner />
  </PublicLayout>
}

export default IndexPage
